<template>
  <CreateUpdateTemplate
    :routePreventDialog="routePreventDialog"
    :customClass="'invoice-create'"
    v-if="getPermission('invoice:create')"
  >
    <template v-slot:header-title>
      <h1 class="form-title">
        <v-layout>
          <v-flex class="flex-grow-0"> Invoice For </v-flex>
          <v-flex
            class="m-0 form-title-create-link pl-2"
            link
            :class="{ 'text-truncate': !lodash.isEmpty(customer) }"
            v-on:click="refCustomerDialog = true"
          >
            <template v-if="lodash.isEmpty(customer)">Client Name</template>
            <template v-else>{{ customer.display_name }}</template>
            <v-icon
              link
              large
              color="cyan"
              class="mx-2"
              v-if="lodash.isEmpty(customer)"
              >mdi-plus-circle-outline</v-icon
            >
          </v-flex>
        </v-layout>
      </h1>
    </template>
    <template v-slot:header-action>
      <v-btn
        v-on:click="goBack()"
        :disabled="formLoading || pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
        depressed
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        color="cyan"
        v-on:click="updateOrCreate()"
      >
        Save Invoice
      </v-btn>
    </template>
    <template v-slot:body>
      <v-form
        ref="invoiceForm"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent="updateOrCreate()"
      >
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: calc(100vh - 185px); position: relative"
        >
          <div class="p-5 pt-0">
            <v-row>
              <v-col cols="12" v-if="getPermission('invoice:create')">
                <v-container fluid>
                  <v-card flat class="remove-border-radius">
                    <v-card-text ref="overview" class="p-6 font-size-16">
                      <v-row dense>
                        <v-col cols="7" class="pb-0">
                          <div>
                            <label class="font-weight-600 required font-size-18"
                              >Invoice Title</label
                            >
                            <v-text-field
                              v-model.trim="invoiceCreate.job_title"
                              dense
                              filled
                              label="Title"
                              solo
                              flat
                              :disabled="pageLoading"
                              :loading="pageLoading"
                              color="cyan"
                              :rules="[
                                validateRules.required(
                                  invoiceCreate.job_title,
                                  'Job Title'
                                ),
                                validateRules.minLength(
                                  invoiceCreate.job_title,
                                  'Job Title',
                                  1
                                ),
                                validateRules.maxLength(
                                  invoiceCreate.job_title,
                                  'Job Title',
                                  100
                                ),
                              ]"
                            ></v-text-field>
                            <template v-if="job_desc_invoice">
                              <v-autocomplete
                                dense
                                color="cyan"
                                filled
                                id="priority"
                                :items="job_desc_invoice"
                                :disabled="pageLoading"
                                :loading="pageLoading"
                                label="Select Description"
                                solo
                                flat
                                item-color="cyan"
                                item-text="name"
                                item-value="id"
                                hide-details
                                v-on:change="get_desc_data"
                              >
                                <template v-slot:no-data>
                                  <v-list-item>
                                    <v-list-item-content>
                                      <v-list-item-title
                                        v-html="'No Description Found.'"
                                      ></v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </template>
                              </v-autocomplete>
                            </template>

                            <v-textarea
                              v-model.trim="invoiceCreate.description"
                              auto-grow
                              dense
                              filled
                              :disabled="pageLoading"
                              :loading="pageLoading"
                              color="cyan"
                              label="Description"
                              solo
                              flat
                              :rules="[
                                validateRules.minLength(
                                  invoiceCreate.description,
                                  'Description',
                                  1
                                ),
                                validateRules.maxLength(
                                  invoiceCreate.description,
                                  'Description',
                                  1024
                                ),
                              ]"
                              row-height="25"
                            ></v-textarea>
                          </div>

                          <v-layout class="my-4">
                            <v-flex md6 class="mr-2 custom-border-right">
                              <table width="100%">
                                <tr>
                                  <td class="font-weight-600">
                                    <label>Service Location</label>
                                    <v-icon
                                      v-if="!invoiceId"
                                      class="ml-3"
                                      v-on:click="customerPropertyDialog = true"
                                      color="cyan"
                                      small
                                      >mdi-pencil</v-icon
                                    >
                                    <v-icon
                                      class="ml-3"
                                      v-on:click="routeToServiceHistory()"
                                      color="cyan"
                                      small
                                      >mdi-history</v-icon
                                    >
                                  </td>
                                </tr>
                                <tr>
                                  <td class="py-0">
                                    <label>{{
                                      property.property_address
                                    }}</label>
                                  </td>
                                </tr>
                                <tr>
                                  <td class="font-weight-600">
                                    <label>Contact details</label>
                                    <v-chip
                                      v-if="property_contact_person.tenant"
                                      small
                                      color="green white--text"
                                      label
                                      class="ml-2 tenant-small-chip"
                                      >Tenant</v-chip
                                    >
                                    <v-icon
                                      v-if="!invoiceId"
                                      class="ml-3"
                                      v-on:click="
                                        person_type = 'property';
                                        customerPersonDialog = true;
                                      "
                                      color="cyan"
                                      small
                                      >mdi-pencil</v-icon
                                    >
                                  </td>
                                </tr>
                                <tr v-if="property_contact_person.display_name">
                                  <td class="py-0">
                                    <label>{{
                                      property_contact_person.display_name
                                    }}</label>
                                  </td>
                                </tr>
                                <tr
                                  v-if="property_contact_person.primary_phone"
                                >
                                  <td class="py-0">
                                    <label>{{
                                      property_contact_person.primary_phone
                                    }}</label>
                                  </td>
                                </tr>
                                <tr
                                  v-if="property_contact_person.primary_email"
                                >
                                  <td class="py-0">
                                    <label>{{
                                      property_contact_person.primary_email
                                    }}</label>
                                  </td>
                                </tr>
                                <tr v-if="!invoiceId">
                                  <td>
                                    <v-layout>
                                      <v-flex
                                        ><label class="font-weight-600">
                                          Email Notification
                                        </label></v-flex
                                      >
                                      <v-flex>
                                        <v-tooltip
                                          top
                                          content-class="custom-top-tooltip"
                                        >
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <v-switch
                                              class="m-0 p-0"
                                              color="cyan"
                                              dense
                                              v-model="
                                                invoiceCreate.property_person_notify
                                              "
                                              inset
                                            ></v-switch>
                                          </template>
                                          <span
                                            v-if="
                                              invoiceCreate.property_person_notify ==
                                              1
                                            "
                                            >Send Notification</span
                                          >
                                          <span v-else>No Notification</span>
                                        </v-tooltip>
                                      </v-flex>
                                    </v-layout>
                                  </td>
                                </tr>
                              </table>
                            </v-flex>
                            <v-flex md6 class="ml-2">
                              <table width="100%">
                                <tr>
                                  <td class="font-weight-600">
                                    <label>Billing Address</label>
                                    <v-icon
                                      v-if="!invoiceId"
                                      class="ml-3"
                                      v-on:click="customerBillingDialog = true"
                                      color="cyan"
                                      small
                                      >mdi-pencil</v-icon
                                    >
                                  </td>
                                </tr>
                                <tr>
                                  <td class="py-0">
                                    <label>{{
                                      billing.property_address
                                    }}</label>
                                  </td>
                                </tr>
                                <tr>
                                  <td class="font-weight-600">
                                    <label>Contact details</label>
                                    <v-chip
                                      v-if="billing_contact_person.tenant"
                                      small
                                      color="green white--text"
                                      label
                                      class="ml-2 tenant-small-chip"
                                      >Tenant</v-chip
                                    >
                                    <v-icon
                                      v-if="!invoiceId"
                                      class="ml-3"
                                      v-on:click="
                                        person_type = 'billing';
                                        customerPersonDialog = true;
                                      "
                                      color="cyan"
                                      small
                                      >mdi-pencil</v-icon
                                    >
                                  </td>
                                </tr>
                                <tr v-if="billing_contact_person.display_name">
                                  <td class="py-0">
                                    <label>{{
                                      billing_contact_person.display_name
                                    }}</label>
                                  </td>
                                </tr>
                                <tr v-if="billing_contact_person.primary_phone">
                                  <td class="py-0">
                                    <label>{{
                                      billing_contact_person.primary_phone
                                    }}</label>
                                  </td>
                                </tr>
                                <tr v-if="billing_contact_person.primary_email">
                                  <td class="py-0">
                                    <label>{{
                                      billing_contact_person.primary_email
                                    }}</label>
                                  </td>
                                </tr>
                                <tr v-if="!invoiceId">
                                  <td>
                                    <v-layout>
                                      <v-flex
                                        ><label class="font-weight-600">
                                          Email Notification
                                        </label></v-flex
                                      >
                                      <v-flex>
                                        <v-tooltip
                                          top
                                          content-class="custom-top-tooltip"
                                        >
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <v-switch
                                              class="m-0 p-0"
                                              color="cyan"
                                              dense
                                              v-model="
                                                invoiceCreate.billing_person_notify
                                              "
                                              inset
                                            ></v-switch>
                                          </template>
                                          <span
                                            v-if="
                                              invoiceCreate.billing_person_notify ==
                                              1
                                            "
                                            >Send Notification</span
                                          >
                                          <span v-else>No Notification</span>
                                        </v-tooltip>
                                      </v-flex>
                                    </v-layout>
                                  </td>
                                </tr>
                              </table>
                            </v-flex>
                          </v-layout>
                        </v-col>
                        <v-col cols="5" class="pb-0">
                          <table width="100%">
                            <!-- <tr>
                              <td width="150" class="font-weight-600">
                                <label>Invoice number</label>
                              </td>
                              <td class="font-weight-700">
                                <label>{{ invoiceCreate.barcode }}</label>
                              </td>
                            </tr> -->

                            <tr v-if="!invoiceId">
                              <td width="150" class="font-weight-600">
                                <label
                                  for="invoice-prefix"
                                  class="required font-size-18"
                                  >Invoice number</label
                                >
                              </td>
                              <td>
                                <v-select
                                  id="invoice-prefix"
                                  :items="invoicetypeList"
                                  dense
                                  filled
                                  solo
                                  flat
                                  hide-details
                                  :disabled="pageLoading"
                                  v-model.trim="invoiceCreate.barcode_id"
                                  label="Suffix Number"
                                  color="cyan"
                                  :item-text="(row) => `${row.prefix}`"
                                  :rules="[
                                    validateRules.required(
                                      invoiceCreate.barcode_id,
                                      'Suffix Number'
                                    ),
                                    validateRules.minLength(
                                      invoiceCreate.barcode_id,
                                      'Suffix Number',
                                      1
                                    ),
                                    validateRules.maxLength(
                                      invoiceCreate.barcode_id,
                                      'Suffix Number',
                                      100
                                    ),
                                  ]"
                                  item-value="id"
                                  item-color="cyan"
                                  v-on:change="getInvoiceNumber()"
                                ></v-select>
                              </td>
                            </tr>
                            <tr>
                              <td width="150" class="font-weight-600">
                                <label for="reference-no">Reference #</label>
                              </td>
                              <td>
                                <v-text-field
                                  id="reference-no"
                                  v-model.trim="invoiceCreate.reference"
                                  dense
                                  filled
                                  :disabled="pageLoading"
                                  :loading="pageLoading"
                                  color="cyan"
                                  label="Reference #"
                                  :rules="[
                                    validateRules.minLength(
                                      invoiceCreate.reference,
                                      'Reference',
                                      1
                                    ),
                                    validateRules.maxLength(
                                      invoiceCreate.reference,
                                      'Reference',
                                      100
                                    ),
                                  ]"
                                  solo
                                  flat
                                ></v-text-field>
                              </td>
                            </tr>
                            <tr>
                              <td width="150" class="font-weight-600">
                                <label for="issued-date">Invoice date</label>
                              </td>
                              <td>
                                <div
                                  v-if="invoiceCreate.issued_type == 1"
                                  class="d-flex"
                                >
                                  <DatePicker
                                    :pageLoading="pageLoading"
                                    solo
                                    placeholder="Invoice date"
                                    v-on:update:date-picker="setIssuedDate"
                                  ></DatePicker>
                                  <div class="my-auto mx-2">
                                    <v-icon
                                      link
                                      color="red lighten-1"
                                      v-on:click="invoiceCreate.issued_type = 0"
                                      >mdi-close</v-icon
                                    >
                                  </div>
                                </div>
                                <v-btn
                                  text
                                  :disabled="pageLoading"
                                  class="custom-bold-button"
                                  v-on:click="invoiceCreate.issued_type = 1"
                                  color="cyan"
                                  v-else
                                >
                                  Date Sent
                                </v-btn>
                              </td>
                            </tr>
                            <tr>
                              <td width="150" class="font-weight-600">
                                <label for="payment-due" class="required"
                                  >Payment due</label
                                >
                              </td>
                              <td>
                                <v-select
                                  id="payment-due"
                                  :items="paymentDueList"
                                  dense
                                  filled
                                  solo
                                  flat
                                  hide-details
                                  :disabled="pageLoading"
                                  v-model.trim="invoiceCreate.payment_due"
                                  label="Payment due"
                                  color="cyan"
                                  item-text="text"
                                  item-value="id"
                                  item-color="cyan"
                                  :rules="[
                                    validateRules.required(
                                      invoiceCreate.payment_due,
                                      'Payment due'
                                    ),
                                    validateRules.minLength(
                                      invoiceCreate.payment_due,
                                      'Payment due',
                                      1
                                    ),
                                    validateRules.maxLength(
                                      invoiceCreate.payment_due,
                                      'Payment due',
                                      100
                                    ),
                                  ]"
                                ></v-select>
                              </td>
                            </tr>
                            <tr v-if="invoiceCreate.payment_due == 5">
                              <td width="150" class="font-weight-600">
                                <label for="due-date">Due date</label>
                              </td>
                              <td>
                                <DatePicker
                                  placeholder="Due date"
                                  solo
                                  :pageLoading="pageLoading"
                                  v-on:update:date-picker="setDueDate"
                                ></DatePicker>
                              </td>
                            </tr>
                            <tr>
                              <td width="155" class="font-weight-600">
                                <label
                                  for="sales-representatives"
                                  class="font-weight-600 required font-size-18"
                                  >Sales representatives</label
                                >
                              </td>
                              <td>
                                <v-autocomplete
                                  dense
                                  color="cyan"
                                  filled
                                  id="sales-representatives"
                                  :items="salesExecutiveList"
                                  :disabled="pageLoading"
                                  :rules="[
                                    validateRules.required(
                                      invoiceCreate.sales_executive,
                                      'Sales representatives'
                                    ),
                                  ]"
                                  :loading="pageLoading"
                                  v-model.trim="invoiceCreate.sales_executive"
                                  label="Sales representatives"
                                  solo
                                  flat
                                  item-color="cyan"
                                  item-text="full_name"
                                  item-value="full_name"
                                  hide-details
                                  v-on:change="updateSales()"
                                >
                                  <template v-slot:no-data>
                                    <v-list-item>
                                      <v-list-item-content>
                                        <v-list-item-title
                                          v-html="
                                            'No Sales Representative Found.'
                                          "
                                        ></v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </template>
                                </v-autocomplete>
                              </td>
                            </tr>
                            <tr>
                              <td width="150" class="font-weight-600">
                                <label for="reference-no"
                                  >Customer Reference #</label
                                >
                              </td>
                              <td>
                                <v-text-field
                                  id="reference-no"
                                  v-model.trim="
                                    invoiceCreate.customer_reference
                                  "
                                  dense
                                  filled
                                  :disabled="pageLoading"
                                  :loading="pageLoading"
                                  color="cyan"
                                  label="Customer Reference #"
                                  :rules="[
                                    validateRules.minLength(
                                      invoiceCreate.customer_reference,
                                      'Reference',
                                      1
                                    ),
                                    validateRules.maxLength(
                                      invoiceCreate.customer_reference,
                                      'Reference',
                                      100
                                    ),
                                  ]"
                                  solo
                                  flat
                                ></v-text-field>
                              </td>
                            </tr>
                            <tr>
                              <td width="150" class="font-weight-600">
                                <label class="font-weight-700 font-size-16"
                                  >Term</label
                                >
                              </td>
                              <td>
                                <v-autocomplete
                                  :items="invoice_term"
                                  dense
                                  filled
                                  color="cyan"
                                  item-color="cyan"
                                  solo
                                  flat
                                  v-on:click:append-outer="
                                    manageBrandDialog = true
                                  "
                                  append-outer-icon="mdi-cog"
                                  placeholder="Select Term"
                                  v-model="invoiceCreate.term_value"
                                  item-text="text"
                                  item-value="value"
                                  class="width-100"
                                >
                                  <template v-slot:no-data>
                                    <v-list-item>
                                      <v-list-item-content>
                                        <v-list-item-title
                                          v-html="'No Term(s) Found.'"
                                        ></v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </template>
                                </v-autocomplete>
                                <template>
                                  <InvoiceTerm
                                    :dialog="manageBrandDialog"
                                    :e_designation="invoice_term"
                                    v-on:get-faults="getOptions"
                                    v-on:close-dialog="
                                      manageBrandDialog = false
                                    "
                                  ></InvoiceTerm>
                                </template>
                              </td>
                            </tr>
                          </table>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-container>
              </v-col>
              <v-col cols="12" v-if="getPermission('line-item:create')">
                <v-container fluid>
                  <v-card flat class="custom-grey-border remove-border-radius">
                    <v-card-title class="headline grey lighten-4">
                      <h3
                        class="font-weight-700 custom-headline color-custom-blue"
                      >
                        Line Items
                      </h3>
                    </v-card-title>
                    <v-card-text ref="lineItem" class="p-6 font-size-16">
                      <v-row dense>
                        <v-col cols="12" class="pt-0 pb-0">
                          <InvoiceLineItemNew
                            is-invoice
                            can-update
                            :property-id="invoiceProperty"
                            :db-line-items="dbLineItems"
                            :db-equipments="dbEquipments"
                            :discount-value="invoiceCreate.discount_value"
                            :discount-value-type="
                              invoiceCreate.discount_value_type
                            "
                            :tax-value="invoiceCreate.tax_value"
                            :apply-tax="invoiceCreate.tax_applied"
                            :adjustment-value="invoiceCreate.adjustment"
                            v-on:update:equipment="updateEquipment($event)"
                            v-on:update:line-item="updateLineItem($event)"
                            v-on:update:line-item-calculation="
                              updateLineItemCalculation($event)
                            "
                            :isInvoicePaid="invoiceStatus"
                          ></InvoiceLineItemNew>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-container>
              </v-col>
              <v-col cols="12">
                <InvoicePaymentTerms
                  :update-data="updateData"
                  :pageLoading="pageLoading"
                ></InvoicePaymentTerms>
              </v-col>
              <v-col cols="12">
                <InvoiceTermsConditions
                  :update-data="updateData"
                  :page-loading="pageLoading"
                ></InvoiceTermsConditions>
              </v-col>
              <v-col cols="12">
                <InvoiceNotesAttachment
                  :update-data="updateData"
                  :page-loading="pageLoading"
                ></InvoiceNotesAttachment>
              </v-col>
            </v-row>
          </div>
        </perfect-scrollbar>
      </v-form>

      <template v-if="refEntityId && refCustomerDialog">
        <RefCustomerDialog
          :entity="refEntityId"
          :customer-dialog="refCustomerDialog"
          v-on:closeDialog="refCloseDialog"
          v-on:resetAll="refResetAll"
          v-on:selectCustomer="refSelectCustomer"
        ></RefCustomerDialog>
      </template>
      <template v-if="refCustomerPropertyDialog">
        <RefCustomerPropertyDialog
          disabled-auto-select
          :customer-property-dialog="refCustomerPropertyDialog"
          :customer="refCustomerId"
          v-on:closeDialog="refCloseDialog"
          v-on:resetAll="refResetAll"
          v-on:selectCustomerProperty="refSelectCustomerProperty"
        ></RefCustomerPropertyDialog>
      </template>

      <template v-if="customerPersonDialog">
        <CustomerPersonDialog
          disabled-auto-select
          :customerPersonDialog="customerPersonDialog"
          :customer="customer.id"
          v-on:close="closeDialog"
          v-on:selectCustomerPerson="selectCustomerPerson"
        ></CustomerPersonDialog>
      </template>
      <template v-if="customerPropertyDialog">
        <CustomerPropertyDialog
          disabled-auto-select
          :customerPropertyDialog="customerPropertyDialog"
          :customer="customer.id"
          v-on:close="closeDialog"
          v-on:selectCustomerProperty="selectCustomerProperty"
        ></CustomerPropertyDialog>
      </template>
      <template v-if="customerBillingDialog">
        <CustomerBillingDialog
          disabled-auto-select
          :customerBillingDialog="customerBillingDialog"
          :customer="customer.id"
          v-on:close="closeDialog"
          v-on:selectCustomerBilling="selectCustomerBilling"
        ></CustomerBillingDialog>
      </template>
    </template>
  </CreateUpdateTemplate>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";
import { QUERY, POST, PUT, GET } from "@/core/services/store/request.module";
import CustomerPersonDialog from "@/view/pages/partials/Select-Customer-Person.vue";
import CustomerPropertyDialog from "@/view/pages/partials/Select-Customer-Property.vue";
import CustomerBillingDialog from "@/view/pages/partials/Select-Customer-Billing.vue";
import InvoiceLineItemNew from "@/view/pages/partials/Line-Item-With-Drag-Drop.vue";
import LineItemMixin from "@/core/lib/line-item/line.item.mixin";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import { TermConditionEventBus } from "@/core/lib/term.condition.lib";
import { NoteAttachmentEventBus } from "@/core/lib/note.attachment.lib";
import InvoicePaymentTerms from "@/view/pages/partials/Payment-Terms.vue";
import InvoiceTermsConditions from "@/view/pages/partials/Terms-Conditions.vue";
import InvoiceNotesAttachment from "@/view/pages/partials/Notes-Attachment.vue";
import { concat, compact, filter, isEmpty, toSafeInteger, map } from "lodash";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import InvoiceTerm from "@/view/pages/partials/invoiceTerm.vue";

import RefCustomerDialog from "@/view/pages/partials/Select-Customer.vue";
import RefCustomerPropertyDialog from "@/view/pages/partials/Select-Customer-Property.vue";
import ObjectPath from "object-path";

export default {
  mixins: [CommonMixin, ValidationMixin, LineItemMixin],
  name: "invoice-create",
  title: "Create Invoice",
  data() {
    return {
      refEntityId: 1,
      refCustomerDialog: false,
      refCustomerPropertyDialog: false,
      isDupChanged: 0,
      refCustomerId: 0,
      refPropertyId: 0,
      invoiceStatus: 0,

      invoice_term: [],
      formLoading: false,
      pageLoading: false,
      manageBrandDialog: false,
      invoiceCreated: false,
      lineItemCreated: false,
      invoicetypeList: [],
      salesExecutiveList: [],
      job_desc_invoice: [],
      customer: {},
      property: {},
      billing: {},
      barcodeSetting: {},
      invoiceCreate: {
        job_title: null,
        description: null,
        property_person_notify: 1,
        customer_reference: null,
        term_value: null,
        billing_person_notify: 1,
        sales_executive: null,
        reference: null,
        issued_type: 0,
        payment_due: null,
        discount_value: null,
        discount_value_type: 1,
        tax_applied: null,
        adjustment: null,
        invoice_date: null,
        due_date: null,
        attention: null,
        gstvalue: null,
        tax_value: 0,
      },
      customerPersonDialog: false,
      customerBillingDialog: false,
      customerPropertyDialog: false,
      customerPerson: 0,
      customerProperty: 0,
      customerBilling: 0,
      person_type: null,
      ticketId: null,
      visitId: null,
      invoiceId: null,

      quotationId: null,
      property_contact_person: {},
      billing_contact_person: {},
      lineItem: [],
      equipments: [],
      dbLineItems: [],
      dbEquipments: [],
      paymentDueList: [],
      noteAttachment: {},
      termsCondition: null,
      paymentTerms: null,
      lineItemCalculation: {
        taxApplied: 0,
        discountValue: 0,
        adjustmentAmount: 0,
        discountType: 1,
        discountValueType: 0,
      },
      updateData: {
        payment_terms: null,
        term_conditions: null,
        admin_remark: null,
        client_remark: null,
        notify_admin: 0,
        notify_customer: 0,
        notify_engineer: 0,
        documents: [],
      },
    };
  },
  components: {
    DatePicker,
    CustomerPersonDialog,
    CustomerPropertyDialog,
    CustomerBillingDialog,
    InvoiceLineItemNew,
    CreateUpdateTemplate,
    InvoicePaymentTerms,
    InvoiceTermsConditions,
    InvoiceNotesAttachment,
    InvoiceTerm,
    RefCustomerDialog,
    RefCustomerPropertyDialog,
  },
  methods: {
    refCloseDialog() {
      this.refCustomerDialog = false;
      this.refCustomerPropertyDialog = false;
    },
    refResetAll() {
      this.refEntityId = 1;
      this.refCustomerDialog = false;
      this.refCustomerPropertyDialog = false;
      this.refCustomerId = 0;
    },
    refSelectCustomer(param) {
      this.refCustomerId = param;
      this.$nextTick(() => {
        this.refCustomerDialog = false;
        this.refCustomerPropertyDialog = true;
      });
    },
    refSelectCustomerProperty(param) {
      this.refPropertyId = param;
      let route = this.getDefaultRoute("invoice.update", {
        query: {
          customer: this.refCustomerId,
          property: this.refPropertyId,
          duplicate: ObjectPath.get(this.$route, "query.duplicate"),
          isDupChanged: 1,
        },
      });
      const resolved = this.$router.resolve(route);
      window.location.href = resolved.href;
    },

    updateSales() {
      const sales_executive = find(this.salesExecutiveList, {
        id: this.invoiceCreate.sales_executive,
      });
      if (sales_executive) {
        this.invoiceCreate.sales = sales_executive.full_name;
      }
    },
    get_desc_data(data) {
      let filteredData = this.lodash.filter(
        this.job_desc_invoice,
        function (row) {
          return row.id == data;
        }
      );
      this.invoiceCreate.description = filteredData[0].description;
    },
    setIssuedDate(param) {
      this.invoiceCreate.invoice_date = param;
    },
    setDueDate(param) {
      this.invoiceCreate.due_date = param;
    },
    updateLineItem(rows) {
      this.lineItem = rows;
    },
    updateEquipment(rows) {
      this.equipments = rows;
    },
    updateLineItemCalculation(row) {
      this.lineItemCalculation.taxApplied = toSafeInteger(row.apply_tax);
      this.lineItemCalculation.discountValue = row.discount_value;
      this.lineItemCalculation.adjustmentAmount = row.adjustment;
      this.lineItemCalculation.discountType = 1;
      this.lineItemCalculation.discountValueType = row.discount_value_type;
    },

    getMultipleInvoice() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "invoice/setting" })
        .then(({ data }) => {
          _this.invoicetypeList = data;
          /* if (data.InvoiceSettings) {
            _this.invoicetypeList = data.InvoiceSettings.map((row) => {
              return { text: row.text };
            });
          } */
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },

    routeToServiceHistory() {
      this.forcePush = true;
      this.$nextTick(() => {
        this.$router.push(
          this.getDefaultRoute("property.detail", {
            params: { id: this.property.id },
            query: {
              tab: "property-history",
            },
          })
        );
      });
    },
    closeDialog() {
      this.customerPersonDialog = false;
      this.customerBillingDialog = false;
      this.customerPropertyDialog = false;
    },
    selectCustomerPerson(param) {
      if (this.person_type == "property") {
        if (this.invoicePropertyPerson == param) {
          this.closeDialog();
          return false;
        }
        this.invoicePropertyPerson = param;
      }
      if (this.person_type == "billing") {
        if (this.invoiceBillingPerson == param) {
          this.closeDialog();
          return false;
        }
        this.invoiceBillingPerson = param;
      }
      this.closeDialog();
      this.pushToRouteInvoice();
    },
    selectCustomerProperty(param) {
      if (this.invoiceProperty == param) {
        this.closeDialog();
        return false;
      }
      this.invoiceProperty = param;
      this.closeDialog();
      this.pushToRouteInvoice();
    },
    selectCustomerBilling(param) {
      if (this.invoiceBilling == param) {
        this.closeDialog();
        return false;
      }
      this.invoiceBilling = param;
      this.closeDialog();
      this.pushToRouteInvoice();
    },
    pushToRouteInvoice() {
      this.$router
        .replace(
          this.getDefaultRoute("invoice.create", {
            query: {
              customer: this.invoiceCustomer,
              billing: this.invoiceBilling,
              property: this.invoiceProperty,
              property_person: this.invoicePropertyPerson,
              billing_person: this.invoiceBillingPerson,
              duplicate: this.duplicateInvoice,
              quotation: this.quotationId,
              ticket: this.ticketId,
              visit: this.visitId,
            },
          })
        )
        .then(() => {
          this.getOptions();
        });
    },
    async updateOrCreate(type) {
      const _this = this;

      const validateStatus = _this.$refs.invoiceForm.validate();

      const formErrors = _this.validateForm(_this.$refs.invoiceForm);

      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      const line_items = concat(_this.equipments, _this.lineItem);

      let validateLineItem = compact(
        map(line_items, function (row) {
          return row.product_id;
        })
      );

      if (isEmpty(validateLineItem)) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Select product/service/equipment then try again.")
        );
        return false;
      }

      if (!validateStatus) {
        return false;
      }

      let REQUEST_TYPE = POST;
      let REQUEST_URL = "invoice";

      if (_this.invoiceId && _this.invoiceId > 0) {
        REQUEST_TYPE = PUT;
        REQUEST_URL = "invoice/" + _this.invoiceId;
      }

      const formData = new Object({
        action: typeof type == "string" ? type : undefined,
        reference: _this.invoiceCreate.reference,
        gstvalue: this.lodash.toSafeInteger(_this.invoiceCreate.tax_value),
        //tax_value: _this.invoiceCreate.tax_value,
        sales: _this.invoiceCreate.sales_executive,
        sales_executive: _this.invoiceCreate.sales_executive,
        customer: _this.invoiceCustomer,
        property: _this.invoiceProperty,
        property_contact_person: _this.invoicePropertyPerson,
        billing_contact_person: _this.invoiceBillingPerson,
        billing: _this.invoiceBilling,
        title: _this.invoiceCreate.job_title,
        description: _this.invoiceCreate.description,
        quotation: _this.quotationId,
        ticket: _this.ticketId,
        visit: _this.visitId,
        attention: _this.invoiceCreate.attention,
        admin_remark: _this.noteAttachment.admin_notes,
        client_remark: _this.noteAttachment.client_notes,
        term_conditions: _this.termsCondition,
        payment_terms: _this.paymentTerms,
        tax_applied: toSafeInteger(_this.lineItemCalculation.taxApplied),
        discount_value: _this.lineItemCalculation.discountValue,
        adjustment: _this.lineItemCalculation.adjustmentAmount,
        discount_type: _this.lineItemCalculation.discountType,
        discount_value_type: _this.lineItemCalculation.discountValueType,
        barcode_id: _this.invoiceCreate.barcode_id,
        property_person_notify: toSafeInteger(
          _this.invoiceCreate.property_person_notify
        ),
        billing_person_notify: toSafeInteger(
          _this.invoiceCreate.billing_person_notify
        ),
        issued_type: _this.invoiceCreate.issued_type,
        payment_due: _this.invoiceCreate.payment_due,
        data: _this.invoiceCreate.data,
        invoice_date: _this.invoiceCreate.invoice_date,
        due_date: _this.invoiceCreate.due_date,
        documents: _this.noteAttachment.documents,
        customer_reference: _this.invoiceCreate.customer_reference,
        term_value: _this.invoiceCreate.term_value,
      });
      _this.formLoading = true;
      if (!_this.invoiceCreated) {
        try {
          const invoice = await _this.$store.dispatch(REQUEST_TYPE, {
            url: REQUEST_URL,
            data: formData,
          });

          if (!isEmpty(invoice)) {
            _this.invoiceId = toSafeInteger(invoice.data.id);
            _this.invoiceCreated = true;
          }
        } catch (error) {
          _this.logError(error);
          _this.formLoading = false;
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("Invoice is not created. Please try again.")
          );
          return false;
        }
      }

      if (!_this.lineItemCreated && _this.invoiceCreated && _this.invoiceId) {
        try {
          await _this.CreateLineItems({
            type: "invoice",
            parent: _this.invoiceId,
            formData: line_items,
          });

          _this.lineItemCreated = true;
        } catch (error) {
          _this.logError(error);
          _this.formLoading = false;
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("Line items are not created. Please try again.")
          );
          return false;
        }
      }

      _this.formLoading = false;

      _this.$nextTick(() => {
        _this.$router.push(
          _this.getDefaultRoute("invoice", {
            query: {
              status: "all",
            },
          })
        );
      });
    },
    getLineItems(data) {
      const _this = this;
      return new Promise((resolve, reject) => {
        _this.$store
          .dispatch(QUERY, {
            url: "line-item/new",
            data,
          })
          .then(({ data }) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    setUpdateData(param) {
      const _this = this;

      _this.invoiceCreate.job_title = param.title;
      _this.invoiceCreate.description = param.description;
      _this.invoiceCreate.property_person_notify = param.property_person_notify;
      _this.invoiceCreate.billing_person_notify = param.billing_person_notify;
      _this.invoiceCreate.reference = param.reference;
      _this.invoiceCreate.issued_type = param.issued_type;
      _this.invoiceCreate.payment_due = param.payment_due;
      _this.invoiceCreate.discount_value = param.discount_value;
      _this.invoiceCreate.discount_value_type = param.discount_value_type;
      _this.invoiceCreate.tax_applied = toSafeInteger(param.tax_applied);
      _this.invoiceCreate.tax_value = this.lodash.toSafeInteger(param.tax_value);
      _this.invoiceCreate.adjustment = param.adjustment;
      _this.invoiceCreate.invoice_date = param.invoice_date;
      _this.invoiceCreate.due_date = param.due_date;
      _this.invoiceCreate.attention = param.attention;
      _this.invoiceStatus = param.status;
      _this.updateData.payment_terms = param.payment_terms;
      _this.updateData.term_conditions = param.term_conditions;
      _this.updateData.admin_remark = param.admin_remark;
      _this.updateData.client_remark = param.client_remark;
      _this.updateData.notify_admin = param.notify_admin;
      _this.updateData.notify_customer = param.notify_customer;
      _this.updateData.documents = param.documents;

      const sales_executive = find(_this.salesExecutiveList, {
        full_name: param.sales,
      });

      if (sales_executive) {
        _this.invoiceCreate.sales = sales_executive.full_name;
        _this.invoiceCreate.sales_executive = sales_executive.full_name;
      }
      _this.invoiceCreate.sales_executive = param.sales;

      _this
        .getLineItems({
          invoice: _this.invoiceId,
          visit: _this.visitId,
        })
        .then((data) => {
          const result = [];
          for (let i = 0; i < data.length; i++) {
            result.push({
              id: data[i].id,
              group: data[i].group,
              group_primary: data[i].group_primary,
              product: data[i].product,
              product_id: data[i].product_id,
              serial_no: data[i].serial_no,
              eq_model: data[i].eq_model,
              location: data[i].location,
              product_type: data[i].product_type,
              has_warranty: data[i].has_warranty,
              warranty: data[i].warranty,
              description: data[i].description,
              rate: data[i].rate,
              quantity: data[i].quantity,
              uom: data[i].uom,
              total: data[i].total,
              visit_barcode: data[i].visit_barcode,
              cu: data[i].cu,
              fcu: data[i].fcu,
            });
          }

          _this.$nextTick(() => {
            _this.dbLineItems = filter(result, function (row) {
              return (
                row.product_type == "goods" || row.product_type == "service"
              );
            });
            _this.dbEquipments = filter(result, {
              product_type: "equipment",
            });
          });
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    setDuplicateData(param) {
      const _this = this;

      _this.invoiceCreate.job_title = param.title;
      _this.invoiceCreate.customer_reference = param.customer_referance;
      _this.invoiceCreate.term_value = param.invoice_term;
      _this.invoiceCreate.description = param.description;
      _this.invoiceCreate.property_person_notify = param.property_person_notify;
      _this.invoiceCreate.billing_person_notify = param.billing_person_notify;
      _this.invoiceCreate.reference = `Invoice # ${param.barcode}`;
      _this.invoiceCreate.issued_type = param.issued_type;
      _this.invoiceCreate.payment_due = param.payment_due;
      _this.invoiceCreate.discount_value = param.discount_value;
      _this.invoiceCreate.discount_value_type = param.discount_value_type;
      _this.invoiceCreate.tax_applied = toSafeInteger(param.tax_applied);
      _this.invoiceCreate.adjustment = param.adjustment;
      _this.invoiceCreate.invoice_date = param.invoice_date;
      _this.invoiceCreate.due_date = param.due_date;
      _this.invoiceCreate.attention = param.attention;
      _this.invoiceCreate.sales_executive = param.sales_executive;
      _this.updateData.payment_terms = param.payment_terms;
      _this.updateData.term_conditions = param.term_conditions;
      _this.updateData.admin_remark = param.admin_remark;
      _this.updateData.client_remark = param.client_remark;
      _this.updateData.notify_admin = param.notify_admin;
      _this.updateData.notify_customer = param.notify_customer;
      _this.updateData.documents = [];

      const sales_executive = find(_this.salesExecutiveList, {
        full_name: param.sales,
      });
      if (sales_executive) {
        _this.invoiceCreate.sales = sales_executive.full_name;
        _this.invoiceCreate.sales_executive = sales_executive.id;
      }
      _this.invoiceCreate.sales_executive = param.sales;

      _this
        .getLineItems({
          invoice: _this.duplicateInvoice,
          visit: _this.visitId,
        })
        .then((data) => {
          const result = [];
          for (let i = 0; i < data.length; i++) {
            result.push({
              id: null,
              group: data[i].group,
              group_primary: data[i].group_primary,
              product: data[i].product,
              product_id: data[i].product_id,
              serial_no: data[i].serial_no,
              eq_model: data[i].eq_model,
              location: data[i].location,
              product_type: data[i].product_type,
              has_warranty: data[i].has_warranty,
              warranty: data[i].warranty,
              description: data[i].description,
              rate: data[i].rate,
              quantity: data[i].quantity,
              uom: data[i].uom,
              total: data[i].total,
              visit_barcode: data[i].visit_barcode,
              cu: data[i].cu,
              fcu: data[i].fcu,
            });
          }

          _this.$nextTick(() => {
            _this.dbLineItems = filter(result, function (row) {
              return (
                row.product_type == "goods" || row.product_type == "service"
              );
            });
            _this.dbEquipments = filter(result, {
              product_type: "equipment",
            });
          });
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    setTicketData(param) {
      const _this = this;

      _this.invoiceCreate.job_title = param.title;
      _this.invoiceCreate.description = param.description;
      _this.invoiceCreate.property_person_notify = param.property_person_notify;
      _this.invoiceCreate.billing_person_notify = param.billing_person_notify;
      _this.invoiceCreate.reference = `Visit # ${param.barcode}`;
      _this.invoiceCreate.discount_value = param.discount_value;
      _this.invoiceCreate.discount_value_type = param.discount_value_type;
      _this.invoiceCreate.tax_applied = toSafeInteger(param.tax_applied);
      _this.invoiceCreate.adjustment = param.adjustment;
      _this.invoiceCreate.invoice_date = param.invoice_date;
      _this.invoiceCreate.due_date = param.due_date;
      _this.invoiceCreate.attention = param.attention;

      _this.updateData.payment_terms = param.payment_terms;
      _this.updateData.term_conditions = param.term_conditions;
      _this.updateData.admin_remark = param.admin_remark;
      _this.updateData.client_remark = param.client_remark;
      _this.updateData.notify_admin = param.notify_admin;
      _this.updateData.notify_customer = param.notify_customer;
      _this.updateData.documents = [];

      _this
        .getLineItems({
          job: _this.ticketId,
          visit: _this.visitId,
        })
        .then((data) => {
          const result = [];
          for (let i = 0; i < data.length; i++) {
            result.push({
              id: null,
              group: data[i].group,
              group_primary: data[i].group_primary,
              product: data[i].product,
              product_id: data[i].product_id,
              serial_no: data[i].serial_no,
              eq_model: data[i].eq_model,
              location: data[i].location,
              product_type: data[i].product_type,
              has_warranty: data[i].has_warranty,
              warranty: data[i].warranty,
              description: data[i].description,
              rate: data[i].rate,
              quantity: data[i].quantity,
              uom: data[i].uom,
              total: data[i].total,
              visit_barcode: data[i].visit_barcode,
              cu: data[i].cu,
              fcu: data[i].fcu,
            });
          }

          _this.$nextTick(() => {
            _this.dbLineItems = filter(result, function (row) {
              return (
                row.product_type == "goods" || row.product_type == "service"
              );
            });
            _this.dbEquipments = filter(result, {
              product_type: "equipment",
            });
          });
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    setQuotationData(param) {
      const _this = this;

      _this.invoiceCreate.job_title = param.job_title;
      _this.invoiceCreate.description = param.description;
      _this.invoiceCreate.property_person_notify = param.property_person_notify;
      _this.invoiceCreate.billing_person_notify = param.billing_person_notify;
      _this.invoiceCreate.reference = `Quotation # ${param.barcode}`;
      _this.invoiceCreate.discount_value = param.discount_value;
      _this.invoiceCreate.discount_value_type = param.discount_value_type;
      _this.invoiceCreate.tax_applied = toSafeInteger(param.tax_applied);
      _this.invoiceCreate.adjustment = param.adjustment;
      _this.invoiceCreate.invoice_date = param.invoice_date;
      _this.invoiceCreate.due_date = param.due_date;
      _this.invoiceCreate.attention = param.attention;

      _this.updateData.payment_terms = param.payment_terms;
      _this.updateData.term_conditions = param.term_conditions;
      _this.updateData.admin_remark = param.admin_remark;
      _this.updateData.client_remark = param.client_remark;
      _this.updateData.notify_admin = param.notify_admin;
      _this.updateData.notify_customer = param.notify_customer;
      _this.updateData.documents = [];

      _this
        .getLineItems({
          quotation: _this.quotationId,
          visit: _this.visitId,
        })
        .then((data) => {
          const result = [];
          for (let i = 0; i < data.length; i++) {
            result.push({
              id: null,
              group: data[i].group,
              group_primary: data[i].group_primary,
              product: data[i].product,
              product_id: data[i].product_id,
              serial_no: data[i].serial_no,
              eq_model: data[i].eq_model,
              location: data[i].location,
              product_type: data[i].product_type,
              has_warranty: data[i].has_warranty,
              warranty: data[i].warranty,
              description: data[i].description,
              rate: data[i].rate,
              quantity: data[i].quantity,
              uom: data[i].uom,
              total: data[i].total,
              visit_barcode: data[i].visit_barcode,
              cu: data[i].cu,
              fcu: data[i].fcu,
            });
          }

          _this.$nextTick(() => {
            _this.dbLineItems = filter(result, function (row) {
              return (
                row.product_type == "goods" || row.product_type == "service"
              );
            });
            _this.dbEquipments = filter(result, {
              product_type: "equipment",
            });
          });
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getOptions() {
      this.pageLoading = true;
      this.$store
        .dispatch(QUERY, {
          url: "invoice/options",
          data: {
            duplicate: this.duplicateInvoice,
            quotation: this.quotationId,
            ticket: this.ticketId,
            visit: this.visitId,
            invoice: this.invoiceId,
            customer: this.invoiceCustomer,
            billing: this.invoiceBilling,
            property: this.invoiceProperty,
            property_person: this.invoicePropertyPerson,
            billing_person: this.invoiceBillingPerson,
          },
        })
        .then(({ data }) => {
          this.updateData.admin_remark = data.options.admin_notes;
          this.updateData.client_remark = data.options.client_note;
          // this.updateData.client_remark = data.options.admin_note;
          if (data.barcode) {
            // this.invoiceCreate.barcode = data.barcode;
          }

          if (data.tax_value) {
            this.invoiceCreate.tax_value = this.lodash.toSafeInteger(data.tax_value);
          }
          if (data.invoice_term) {
            this.invoice_term = data.invoice_term;
          }
          if (data.invoice) {
            this.invoiceCreate.term_value = data.invoice.invoice_term;
          }

          if (data.invoice) {
            this.invoiceCreate.customer_reference =
              data.invoice.customer_referance;
          }

          if (!isEmpty(data.payment_terms)) {
            this.paymentDueList = data.payment_terms;
          }

          /* if (!isEmpty(data)) {
            this.invoicetypeList = data.prefix;
          } */

          if (!isEmpty(data.options)) {
            this.updateData.client_remark = this.barcodeSetting.admin_note;
          }

          if (!isEmpty(data.options)) {
            this.updateData.term_conditions = data.options.terms_conditions;
          }

          if (!isEmpty(data.options)) {
            this.barcodeSetting = data.options;
            this.updateData.admin_remark = this.barcodeSetting.admin_note;
          }
          if (!isEmpty(data.options)) {
            this.barcodeSetting = data.options;
            this.updateData.client_remark = this.barcodeSetting.client_note;
          }
          if (!isEmpty(data.sales_users)) {
            this.salesExecutiveList = data.sales_users;
          }

          if (!isEmpty(data.invoice)) {
            this.setUpdateData(data.invoice);
          }

          if (!isEmpty(data.quotation)) {
            this.setQuotationData(data.quotation);
          }

          if (!isEmpty(data.ticket)) {
            this.setTicketData(data.ticket);
          }

          if (!isEmpty(data.duplicate)) {
            this.setDuplicateData(data.duplicate);
          }

          if (!isEmpty(data.customer)) {
            this.customer = data.customer;
            this.invoiceCustomer = this.customer.id;
          }

          if (!isEmpty(data.billing)) {
            this.billing = data.billing;
            this.invoiceBilling = this.billing.id;
          }

          if (!isEmpty(data.property)) {
            this.property = data.property;
            this.invoiceProperty = this.property.id;
          }

          if (!isEmpty(data.property_person)) {
            this.property_contact_person = data.property_person;
            this.invoiceCreate.attention =
              this.property_contact_person.display_name;
            this.invoicePropertyPerson = this.property_contact_person.id;
          }

          if (!isEmpty(data.billing_person)) {
            this.billing_contact_person = data.billing_person;
            this.invoiceBillingPerson = this.billing_contact_person.id;
          }

          if (!isEmpty(data.job_desc_invoice)) {
            this.job_desc_invoice = data.job_desc_invoice;
          }
        })
        .catch((error) => {
          this.logError(error);
          this.goBack();
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },
  },
  mounted() {
    this.getMultipleInvoice();
    this.getOptions();

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Invoice", route: "invoice" },
      { title: "Create" },
    ]);

    TermConditionEventBus.$on("update:payment-terms", (argument) => {
      this.paymentTerms = argument;
    });

    TermConditionEventBus.$on("update:term-condition", (argument) => {
      this.termsCondition = argument;
    });

    NoteAttachmentEventBus.$on("update:notes-attachment", (argument) => {
      this.noteAttachment = argument;
    });
  },

  beforeMount() {
    this.invoiceId = toSafeInteger(this.$route.params.id);
    this.invoiceCustomer = toSafeInteger(this.$route.query.customer);
    this.invoiceBilling = toSafeInteger(this.$route.query.billing);
    this.invoiceProperty = toSafeInteger(this.$route.query.property);
    this.invoicePropertyPerson = toSafeInteger(
      this.$route.query.property_person
    );
    this.invoiceBillingPerson = toSafeInteger(this.$route.query.billing_person);
    this.duplicateInvoice = toSafeInteger(this.$route.query.duplicate);
    this.ticketId = toSafeInteger(this.$route.query.ticket);
    this.visitId = toSafeInteger(this.$route.query.visit);
    this.quotationId = toSafeInteger(this.$route.query.quotation);
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.forcePush) {
      next();
    } else if (_this.invoiceCreated && _this.lineItemCreated) {
      next();
    } else {
      _this.routePreventDialog = true;
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  },
};
</script>
